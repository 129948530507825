export const menuItems = {

    // header menu items
    header: [
      {
        label: "Home",
        to: "/",
        dropdown: false,
      },
      {
        label: "About",
        to: "",
        dropdown: true,
        items: [
          { label: "About the Logo", to: "/about-the-logo" },
          { label: "Host and Committee", to: "/host-and-committee" },
          { label: "Latest News", to: "/latest-news" },
          { label: "Promotional Toolkit", to: "/promotional-toolkit" },
        ],
      },
      {
        label: "Destination",
        to: "",
        dropdown: true,
        items: [
          { label: "Visit Victoria", to: "/visit-victoria" },
          { label: "Melbourne Bucket List", to: "/melbourne-bucket-list" },
          { label: "Groundwater Bucket List", to: "/groundwater-bucket-list" },
          { label: "Congress Venue", to: "/congress-venue" }
        ],
      },
      {
        label: "Registration",
        to: "",
        dropdown: true,
        items: [
          { label: "Registration", to: "/registration" },
          // { label: "Support Grants", to: "/support-grants" },
          { label: "Accommodation", to: "/accommodation" },
          { label: "Social Functions", to: "/social-functions" },
          { label: "Merchandise", to: "/merchandise" },
        ]
      },
      {
        label: "Program",
        to: "",
        dropdown: true,
        items: [
          // { label: "Call for Abstracts", to: "/call-for-abstracts" },
          { label: "Program Overview", to: "/program-overview" },
          { label: "Speakers", to: "/speakers" },
          { label: "Field Trips", to: "/field-trips" },
        ],
      },
      {
        label: "Sponsorship",
        to: "",
        dropdown: true,
        items: [
          { label: "Sponsorship", to: "/sponsorship" },
          { label: "Sponsors & Exhibitors", to: "/sponsors-and-exhibitors" },
        ],
      },
      {
        label: "Information",
        to: "",
        dropdown: true,
        items: [
          { label: "Contact Us", to: "/contact" },
          { label: "Privacy Policy", to: "/privacy-policy" },
        ],
      },
    ],

    // footer menu items
    footer: [
      {
        title: "About",
        links: [
          { name: "Our Story", to: "#" },
          { name: "Our Team", to: "#" },
          { name: "Careers", to: "#" },
        ],
      },
      {
        title: "Program",
        links: [
          { name: "Layouts", to: "#" },
          { name: "Templates", to: "#" },
          { name: "Themes", to: "#" },
        ],
      },
      {
        title: "Destination",
        links: [
          { name: "Documentation", to: "#" },
          { name: "Blog", to: "#" },
          { name: "Community", to: "#" },
        ],
      },
      {
        title: "Contact",
        links: [
          { name: "Support", to: "#" },
          { name: "Sales", to: "#" },
          { name: "Partnerships", to: "#" },
        ],
      },
    ],
  };
