import React from "react";
import { Link } from "gatsby";
import { Button } from "@/components/ui/button";
import UserForm from '@/components/form/EOI-Form';


export default function Footer() {

  const eventName = 'IAH 2025';

  return (
    <footer className="w-full bg-gray-900 text-white py-12 md:py-16">
      <section className="pb-5">
        <div className="w-full">
          <div className="flex items-center justify-center w-full bg-gray-900">
            <div className="flex flex-col lg:flex-row items-center justify-center w-full container p-8 rounded-lg bg-gray-900">
              <div className="w-full lg:w-1/2 pr-0 lg:pr-8 lg:border-r pb-8 lg:pb-0 border-gray-700 dark:border-gray-700 dark-theme">
                <h2 className="text-3xl font-bold mb-4 text-white">Express your interest</h2>
                <UserForm eventName={eventName} />
              </div>
              <div className="w-full lg:w-1/2 pl-8 hidden lg:block xl:flex flex-col gap-10">
                <Link to="/" className="mx-auto">
                  <img src="/IAH2025-full-pos-web.svg" alt="IAH 2025 logo" width={500} />
                </Link>
                <div>
                  <h2 className="text-3xl font-bold mb-4 text-white">Welcome to IAH2025</h2>
                  <p className="text-gray-400 dark:text-gray-400">
                    Join us for a remarkable event celebrating the expertise and innovation in groundwater science and sustainability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mx-auto px-4 md:px-6 pt-10 flex flex-col lg:flex-row gap-8 border-t border-slate-600">
        <h2 className="lg:w-1/3">Acknowledgement of Country</h2>
        <div className="lg:w-2/3 text-slate-400">
          <p>IAH 2025 acknowledges Aboriginal and Torres Strait Islander peoples as the Traditional Custodians of the land and sea in which we live and work. We recognise their continuing connection to land, sea and culture and pay our respects to Elders past, present and future. </p>
          <p>In particular the Congress venue, Melbourne Convention and Exhibition Centre (MCEC), is built on the banks of the Birrarung (Yarra River), we pay our respects to the Traditional Owners of Narrm, the Wurundjeri Woi Wurrung people of the Kulin Nation.</p>
          {/* <Button variant="secondary" size="lg" asChild><a href="http://declaration.iah.org.au/" target="_blank" rel="noopener noreferrer">IAH Australia Declaration</a></Button> */}
        </div>
      </section>
      
      <div className="container mx-auto px-4 md:px-6 mt-8 text-center text-gray-400 pt-10 border-t border-slate-600">
        © 2025 ICMSA. All rights reserved. | <a href="https://icmsaust.com.au/privacy-policy/" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">Privacy Policy</a>
      </div>
    </footer>
  );
}
